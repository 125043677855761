<script>
import { required, email } from "vuelidate/lib/validators";
import { ParticlesBg } from "particles-bg-vue";
import { authMethods, notificationMethods } from "@/state/helpers";
import { auth } from "@/config/api/auth";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authenticating: false,
      showPassword: false,
      userType: "a",
    };
  },
  components: {
    ParticlesBg,
  },

  created() {
    document.body.classList.add("auth-body-bg");
  },

  validations: {
    email: { required, email },
    password: { required },
  },

  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      //stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { email, password } = this;
        if (email && password && this.userType) {
          this.authenticating = true;
          const api =
            this.userType === "a" ? auth.adminLogin : auth.vendorLogin;
          api.data = {
            email,
            password,
          };
          this.logIn({ api })
            .then(() => {
              if (this.userType === "a") {
                this.$router.push({ name: "home" });
              } else {
                this.$router.push({ name: "vendorDashboard" });
              }
            })
            .catch((err) => {
              console.log(err);
              if (!err.isAuth) {
                this.showError(err.message);
              }
            })
            .finally(() => {
              this.authenticating = false;
            });
        }
      }
    },
    showError(msg) {
      this.$bvToast.toast(msg, {
        title: "Authentication",
        variant: "danger",
        solid: true,
      });
    },
  },
};
</script>
<template>
  <div>
    <div
      style="
        background-color: #ffffffa6;
        width: 100%;
        height: 100%;
        position: absolute;
      "
    >
      <particles-bg
        type="color"
        num="1"
        :canvas="{ backgroundColor: '#fff' }"
        :bg="true"
      />
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-5">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
              <img
                src="@/assets/images/auth/Illustration.png"
                style="padding-top: 48px"
              />
            </div>
          </div>
          <div class="col-lg-7">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div style="max-width: 412px; margin: auto">
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/Logo_Dark.svg"
                              height="64"
                              alt="logo"
                            />
                          </a>
                        </div>
                        <h4 class="font-size-18 mt-4">Welcome Back!</h4>
                        <h5 class="font-size-14 mt-2">Login as Admin</h5>
                      </div>

                      <div class="mt-4 text-center user-type">
                        <label>
                          <input
                            type="radio"
                            value="a"
                            default="true"
                            name="options"
                            v-model="userType"
                            autocomplete="off"
                          />
                          <span class="btn btn-outline-light m-1" for="btnAdmin"
                            >Admin</span
                          >
                        </label>
                        <label>
                          <input
                            style="margin-left: 10px"
                            type="radio"
                            value="v"
                            name="options"
                            v-model="userType"
                            autocomplete="off"
                          />
                          <span
                            class="btn btn-outline-light m-1"
                            for="btnVendor2"
                            >Vendor</span
                          >
                        </label>
                      </div>
                      <form
                        class="form-horizontal"
                        @submit.prevent="tryToLogIn"
                      >
                        <label for="email">Email</label>
                        <input
                          type="email"
                          v-model="email"
                          class="form-control"
                          id="email"
                          placeholder="Enter email"
                          :class="{
                            'is-invalid': submitted && $v.email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Email is required.</span
                          >
                          <span v-if="!$v.email.email"
                            >Please enter valid email.</span
                          >
                        </div>

                        <label class="mt-3" for="password">Password</label>
                        <div class="form-group auth-form-group-custom">
                          <i class="ri-lock-2-line auti-custom-input-icon"></i>
                          <input
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            class="form-control"
                            id="userpassword"
                            placeholder="Enter password"
                            style="
                              padding-left: 52px;
                              padding-right: 44px;
                              padding-top: 6px;
                              height: 40px;
                            "
                            :class="{
                              'is-invalid': submitted && $v.password.$error,
                            }"
                          />
                          <button
                            type="button"
                            @click="showPassword = !showPassword"
                            class="show-password-btn"
                          >
                            <i v-if="!showPassword" class="fas fa-eye"></i>
                            <i v-else class="fas fa-eye-slash"></i>
                          </button>
                          <div
                            v-if="submitted && !$v.password.required"
                            class="invalid-feedback"
                          >
                            Password is required.
                          </div>
                        </div>

                        <div class="mt-5 text-center">
                          <button
                            class="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            <span v-if="authenticating">Authenticating...</span>
                            <span v-else>Sign In</span>
                          </button>
                        </div>
                      </form>

                      <div class="mt-4 text-center">
                        <router-link
                          tag="a"
                          to="/forgot-password"
                          class="text-muted"
                        >
                          <i class="mdi mdi-lock mr-1"></i> Forgot your
                          password?
                        </router-link>
                      </div>
                    </div>
                    <div class="mt-5 text-center">
                      <p>
                        © 2022 nomadictribe.com <br />Developed by MVP-APPS.AE
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-type [type="radio"]:checked + span {
  border: 2px solid #fa7da9;
  color: #000;
}

.user-type [type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none;
}
.show-password-btn {
  border: none;
  position: absolute;
  top: -8px;
  right: 12px;
  width: 28px;
  height: 60px;
  background-color: transparent;
}
.show-password-btn i {
  font-size: 22px;
}
</style>
